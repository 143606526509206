import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IndiceService } from '../../shared/services/indice.service';
import { IndiceInsee } from '../../shared/model/indice-insee';
import { TypeInseeEnum } from "../../shared/model/type-insee.enum";
import { MatDialog } from '@angular/material/dialog';
import { IndiceComponent } from './indice/indice.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-manage-indice',
  templateUrl: './manage-indice.component.html',
  styleUrls: ['./manage-indice.component.scss']
})
export class ManageIndiceComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['annee', 'trimestre', 'valeur', 'type','dateParution'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

indicesInsee: IndiceInsee[] = [];
dataSource: MatTableDataSource<any> = new MatTableDataSource<any>(this.indicesInsee);;

  constructor(private indiceService: IndiceService, public dialog: MatDialog) {

  }

  ngOnInit(): void {
   
    this.getListInsee();

  }
  getListInsee() {
    this.indiceService.getListIndice()
    .subscribe((sortie) => {
      this.indicesInsee = sortie;
      this.dataSource = new MatTableDataSource<any>(this.indicesInsee);
      this.dataSource.paginator = this.paginator;
    })
  }
  ngAfterViewInit() {

  }

  newIndice() {

    const dialogRef = this.dialog.open(IndiceComponent, { 
      width: '500px',
      data: {
        user: null,
        mode: ''
      }
    })
    dialogRef.afterClosed()
    .subscribe((isOk) => {
      this.getListInsee();
    })
  }
  applyFilter(event) {

  }
  refresh() {

  }
  open() {

  }
}
