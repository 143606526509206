<mat-tab-group class="groupe" animationDuration="1000ms">
    <mat-tab label="Classement" style="padding:0px">

        <div class="col-sm-12" style="padding:0px;">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <mat-toolbar>
                                <button mat-icon-button class="example-icon"
                                    aria-label="Example icon-button with menu icon">
                                    <mat-icon>menu</mat-icon>
                                </button>
                                <span>Dossiers</span>
                                <span class="header-spacer"></span>
                                <button mat-icon-button class="example-icon favorite-icon"
                                    matTooltip="Créer un nouveau dossier" (click)="openModal()"
                                    aria-label="Example icon-button with heart icon">
                                    <mat-icon>create_new_folder</mat-icon>
                                </button>
                                <button mat-icon-button class="example-icon favorite-icon"
                                    matTooltip="Modifier le dossier sélectionné" (click)="modifier()"
                                    aria-label="Example icon-button with heart icon">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-icon-button class="example-icon" matTooltip="Recharger les dossiers"
                                    (click)="refresh()" aria-label="Example icon-button with share icon">
                                    <mat-icon>cached</mat-icon>
                                </button>
                            </mat-toolbar>

                            <fieldset>
                                <div class="session-modal-container"></div>
                                <app-tree [listDossiers]="listDossiers" (dossEmitter)="onClick($event)"></app-tree>
                            </fieldset>
                            <div _ngcontent-ckb-c229="" class="card-body btn-showcase">
                                <!--   <button _ngcontent-ckb-c229="" type="button" placement="top" ngbtooltip="btn btn-primary" class="btn btn-primary btn-xs">Primary Button</button>
                               <button _ngcontent-ckb-c229="" type="button" placement="top" ngbtooltip="btn btn-secondary" class="btn btn-secondary btn-xs">Secondary Button</button>-->

                                <!--<button  mat-raised-button matTooltip="Créer un nouveau dossier" color="primary" class="ml-10" (click)="openModal()">Nouveau</button>
                               <button  mat-stroked-button matTooltip="Modifier le dossier sélectionné" color="primary"   (click)="modifier()">Modifier</button>
                               <button  mat-stroked-button matTooltip="Rafraichir les dossier" color="primary"   (click)="refresh()">Recharger</button>
                            -->
                                <!-- <button _ngcontent-ckb-c229="" type="button" placement="top" ngbtooltip="btn btn-warning" class="btn btn-warning btn-xs">Warning Button</button>
                               <button _ngcontent-ckb-c229="" type="button" placement="top" ngbtooltip="btn btn-danger" class="btn btn-danger btn-xs">Danger Button</button>
                               <button _ngcontent-ckb-c229="" type="button" placement="top" ngbtooltip="btn btn-light" class="btn btn-light btn-xs">Light Button</button>-->
                            </div>
                        </div>

                        <div class="col-6" *ngIf="dossier!=undefined">
                            <div>
                                <h2>Propriétés de "{{dossier.name}}"</h2>
                            </div>
                            <fieldset>

                                <app-property></app-property>
                            </fieldset>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="col-sm-12" *ngIf="dossier">
            <div class="card">
                <div class="card-header">
                    <h4>Ajouter des propriétés au dossier {{dossier?.name}}</h4>
                </div>
                <div class="card-body">
                    <div class="btn-showcase">
                        <button mat-raised-button matTooltip="Créer une nouvelle propriété" class="ml-10 bg-primary"
                            (click)="addPropriete()">Nouvelle propriété</button>
                        <button mat-stroked-button matTooltip="Enregistrer les propriétés sélectionnées dans le dossier"
                            class="txt-primary" (click)="enregistrerDossier()">Enregistrer</button>
                    </div>
                    <div class="parent drag-n-drop">
                        <div class="wrapper container-fluid">

                            <div class="container">
                                <h4>Propriétés disponibles</h4>
                                <div class="hZone"></div>
                                <div class="container-fluid scroll" [dragula]="MANY_ITEMS"
                                    [(dragulaModel)]="properties">

                                    <ng-container *ngFor="let p of properties | propFilter:{key:searchText}">
                                        <div *ngIf="p?.key" [id]="p?.propertyId">
                                            {{ p?.key }}
                                        </div>
                                    </ng-container>

                                </div>
                            </div>
                            <div class="container">
                                <h4>Propriétés sélectionnées</h4>
                                <div class="hZone">&nbsp;</div>
                                <div class="container-fluid scroll" [dragula]="MANY_ITEMS"
                                    [(dragulaModel)]="dossier.properties">

                                    <ng-container *ngFor="let p of dossier?.properties">
                                        <div *ngIf="p?.key" [id]="p?.propertyId">
                                            {{ p?.key }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="card-footer">

                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Listes" style="padding:0px">
        <div class="contenu" style="padding:0px;">
            <app-reference></app-reference>
        </div>
    </mat-tab>
    <mat-tab label="Clients" style="padding:0px">
        <div class="contenu" style="padding:0px;">
            <app-manage-users></app-manage-users>
        </div>
    </mat-tab>

    <mat-tab label="Indice INSEE" style="padding:0px">
        <div class="contenu" style="padding:0px;">
            <app-manage-indice></app-manage-indice>
        </div>
    </mat-tab>



</mat-tab-group>