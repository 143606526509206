import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilisateurComponent } from '../utilisateur/utilisateur.component';
import { Utilisateur } from '../../shared/model/utilisateur';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UtilisateurService } from '../../shared/services/utilisateur.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataUploaderComponent } from 'src/app/shared/data-uploader/data-uploader.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  userList: Utilisateur[] = [];

  user: Utilisateur = { id: null, firstName: null, lastName: null, username: null, email: null, enabled: true, created: new Date(), updated: new Date(), credentials: [], enterprise: null };
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>(this.userList);;

  constructor(
    private utilisateurService: UtilisateurService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.userList.push(this.user);
    this.dataSource = new MatTableDataSource<any>(this.userList);
    this.getAllUsers();


  }
  refresh() {
    this.getAllUsers();
  }
  applyFilter(e: Event) {

  }
  getAllUsers() {
    this.utilisateurService.getAllUsers().subscribe(result => {
      // this.userList = result;
      console.log("data", result);
      this.dataSource = new MatTableDataSource<any>(result);
    })
  }


  displayedColumns: string[] = ['id', 'firstName', 'lastName', 'username', 'email', 'enabled'];


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  newUser() {
    console.log("User : ", this.user);
    let dialogRef = this.dialog.open(UtilisateurComponent, {
      width: '500px',
      data: this.user,
    });
  }

  updateUser(user: Utilisateur) {
    console.log("User : ", user);
    let dialogRef = this.dialog.open(UtilisateurComponent, {
      width: '500px',
      data: {
        user: user,
        mode: 'update'
      }
    });
  }
  uploadFile() {
    let dialogRef = this.dialog.open(DataUploaderComponent, {
      data: environment.backendUrl + "utilisateur/api/excel"
    });
  }

}
