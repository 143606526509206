<div class="card">
    <div class="card-header">
        <h2>Nouvel indice</h2>
    </div>
    <div class="card-body">
        <form [formGroup]="indiceForm" class="container-fluid form-general" (submit)="createIndice()">
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Année</mat-label>
                    <input matInput formControlName="annee" required>
                </mat-form-field>
                <mat-error *ngIf="annee.hasError('required')">L'année est obligatoire</mat-error>
            </div>

            <div class="form-group">
                <mat-form-field appearance="fill">
                    <mat-label>Trimestre</mat-label>
                    <mat-select formControlName="trimestre" required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let tm of trimestres" [value]="tm.name">
                            {{tm.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="trimestre.hasError('required')">Le trimestre est obligatoire</mat-error>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field appearance="fill">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let type of typeILCs" [value]="type.name">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="type.hasError('required')">Le type insee est obligatoire</mat-error>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Valeur</mat-label>
                    <input matInput formControlName="value" required>
                </mat-form-field>
                <mat-error *ngIf="value.hasError('required')">La valeur est obligatoire</mat-error>
            </div>

            <div class="form-group">
                <mat-form-field appearance="fill">
                    <mat-label>Date de parution</mat-label>
                    <input matInput type="date" formControlName="dateParution" required>
                   <!--<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>-->
                </mat-form-field>
                <mat-error *ngIf="dateParution.hasError('required')">La date de parution est obligatoire</mat-error>
            </div>
            <div class="card-footer">
                <button class="mr-10" [disabled]="!indiceForm.valid" mat-raised-button type="submit"
                    color="primary">Enregistrer</button>
                <button mat-stroked-button class="ml-10" color="primary" (click)="closeDialog()">Annuler</button>
            </div>
        </form>
    </div>
</div>