// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendUrl: '/backend/vusadoc/',
  //backendUrl: 'https:/backend.albasoft.fr/vusadoc/',
  elk_url: "/elkle/",
  dossier: "dossier/",
  allUsers: "utilisateur/api/users/all",
  document: "document/",
  documentcreate: "document/create",
  documentdelete: "document/delete",
  documentJournal: "document/journal",
  documentMessage: 'document/messages',
  documentMessageCreate: 'document/message/create',
  property: 'property/',
  propertyvalue: "propertyvalue/",
  emailUrl: 'send',
  baseUrl: 'https://salimto.vusadoc.fr',
  access_token: "",
  realm: 'https://salimto.vusadoc.fr/auth/realms/vusadoc',
  redirectUri: 'https://salimto.vusadoc.fr/',
  issuer: 'https://salimto.vusadoc.fr/auth/realms/vusadoc',
  index_pref: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
